import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import FilterIcon from '@material-ui/icons/FilterList'

import displayTypes from 'store/display/types'
import { darkTheme } from 'styles/themeClasses'

const useStyles = makeStyles((theme) => ({
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
  pointer: {
    cursor: 'pointer',
  },
}))

const TypeToggle = () => {
  const dispatch = useDispatch()
  const classes = useStyles(darkTheme)

  const { typeFilter, typeTooltip } = useSelector(state => state.display)

  return (
    <Box className={classes.action}>
      <Tooltip
        title="Toggle type (Flow/Campaign)"
        aria-label="Toggle type"
      >
        <IconButton onClick={() => dispatch({ type: displayTypes.TOGGLE_FLOW_CAMPAIGN_FILTER })}>
          <FilterIcon />
        </IconButton>
      </Tooltip>
      {typeFilter && (
        <Tooltip
          title={typeTooltip}
          className={classes.pointer}
        >
          <span>{typeFilter}</span>
        </Tooltip>
      )}
    </Box>
  )
}

export default TypeToggle
