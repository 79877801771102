import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'

import StatsTableCell from './StatsTableCell'
import DeviationFlag from './DeviationFlag'

import { getAllowedDeviation } from 'store/display/selectors'

const finalClass = 'finalColumns'
const totalFinalClass = 'totalFinalColumn'

const FinalSection = ({ stats, averageStats, show }) => {
  const allowedDeviation = useSelector(getAllowedDeviation)

  if (!show) return null

  const {
    impl,
    qc,
    fqc,
    bicoe,
    overallTotal,
  } = stats

  const hasFinalValue = impl || qc || fqc

  return (
    <>
      <StatsTableCell value={impl} className={finalClass} />
      <StatsTableCell value={qc} className={finalClass} />
      <StatsTableCell value={fqc} className={finalClass} />
      <TableCell className={finalClass}>
        {bicoe || ''}
        {!!bicoe && overallTotal && (
          <DeviationFlag
            value={bicoe}
            average={averageStats?.implQlty?.bicoe?.average}
            tolerance={allowedDeviation.bicoe}
          />
        )}
      </TableCell>
      <StatsTableCell
        value={overallTotal}
        className={totalFinalClass}
        average={averageStats?.average}
        tolerance={allowedDeviation.overall}
        isComplete={hasFinalValue}
      />
    </>
  );
}

FinalSection.propTypes = {
  show: PropTypes.bool,
  stats: PropTypes.object,
  averageStats: PropTypes.object,
};

export default FinalSection;
