import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { MainListItems } from './listItems'

import { useStyles, darkTheme } from 'styles/themeClasses'

const AppDrawer = ({ handleDrawerClose, open }) => {
  const classes = useStyles(darkTheme)

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton
          onClick={handleDrawerClose}
          className={clsx(!open && classes.hide)}
        >
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <MainListItems />
      </List>
    </Drawer>
  )
}

AppDrawer.propTypes = {
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool,
}

export default AppDrawer
