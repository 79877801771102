import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CheckIcon from '@material-ui/icons/Check'

import { msToTime } from 'lib'

import 'styles/index.scss'

const tableStyles = makeStyles(() => ({
  positiveOutOfToleranceFlag: {
    cursor: 'pointer',
    fontSize: 12,
    color: 'green',
    marginLeft: '4px',
  },
  negativeOutOfToleranceFlag: {
    cursor: 'pointer',
    fontSize: 12,
    color: 'red',
    marginLeft: '4px',
  },
  withinTolerance: {
    cursor: 'pointer',
    fontSize: 12,
    color: 'white',
    marginLeft: '4px',
  },
}))

const DeviationFlag = ({ value, average, tolerance, type }) => {
  if (!value || !average || !tolerance) return null

  const tableClasses = tableStyles()

  let deviation, isPositiveDeviation, outOfTolerance, title

  switch (type) {
    case 'percentage': {
      deviation = (value * 100) / average - 100
      outOfTolerance = Math.abs(deviation) > tolerance

      title = `Deviation of ${deviation.toFixed(2)}% from average.`
      break
    }
    case 'days': {
      const toleranceDaysToMs = tolerance * 8.64e7

      deviation = value - average
      outOfTolerance = Math.abs(deviation) > toleranceDaysToMs

      const deviationMsToTime = msToTime(deviation)
      title = `Deviation of ${deviationMsToTime} from average.`
      break
    }
    default: {
      deviation = value - average
      outOfTolerance = Math.abs(deviation) > tolerance

      title = `Deviation of ${deviation.toFixed(2)} from average.`
    }
  }

  isPositiveDeviation = Math.sign(deviation) === 1

  if (!outOfTolerance)
    return (
      <Tooltip title="Within average tolerance">
        <CheckIcon classes={{ root: tableClasses.withinTolerance }} />
      </Tooltip>
    )

  if (isPositiveDeviation) {
    return (
      <Tooltip title={title}>
        <ArrowUpwardIcon
          classes={{ root: tableClasses.negativeOutOfToleranceFlag }}
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip title={title}>
      <ArrowDownwardIcon
        classes={{ root: tableClasses.positiveOutOfToleranceFlag }}
      />
    </Tooltip>
  )
}

DeviationFlag.propTypes = {
  value: PropTypes.number,
  average: PropTypes.number,
  tolerance: PropTypes.number,
  type: PropTypes.string,
}

export default DeviationFlag
