import { format, subDays } from 'date-fns'

import types from './types'

const today = new Date()

const tableColumnsDisplay = ['copy', 'design', 'final']

const initialState = {
  startDate: format(subDays(today, 30), 'yyyy-MM-dd'),
  endDate: format(today, 'yyyy-MM-dd'),
  allowedDeviation: {
    copyTeam: 1,
    designTeam: 1,
    overall: 1,
    atr: 1,
    bicoe: 1,
  },
  loading: {},
  typeFilter: '',
  typeTooltip: '',
  tableColumnsDisplay,
  emailTypeFilter: ['all'],
}

const allowedDeviationStorageKey = 'allowedDeviation'
const allowedDeviationSerializedState = localStorage.getItem(
  allowedDeviationStorageKey
)
if (allowedDeviationSerializedState) {
  initialState.allowedDeviation = {
    ...initialState.allowedDeviation,
    ...allowedDeviationSerializedState,
  }
}

export default function displayReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_DATE_FILTER: {
      const { dateProp, dateValue } = action.payload || {}

      return { ...state, [dateProp]: dateValue }
    }
    case types.SET_ALLOWED_DEVIATION: {
      const newState = { ...state, allowedDeviation: action.payload }
      localStorage.setItem(
        allowedDeviationStorageKey,
        JSON.stringify(newState.allowedDeviation)
      )

      return newState
    }
    case types.SET_TABLE_COLS_DISPLAY: {
      return { ...state, tableColumnsDisplay: action.payload || tableColumnsDisplay }
    }
    case types.TOGGLE_FLOW_CAMPAIGN_FILTER: {
      const filter = state.typeFilter
      let typeFilter = '', typeTooltip = ''

      if (!filter) {
        typeFilter = 'F'
        typeTooltip = 'Flow'
      } else if (filter === 'F') {
        typeFilter = 'C'
        typeTooltip = 'Campaign'
      }

      return { ...state, typeFilter, typeTooltip }
    }
    case types.SET_EMAIL_TYPE_FILTER: {
      if (action.payload.length === 0 || action.payload[action.payload.length - 1] === 'all')
        return { ...state, emailTypeFilter: ['all'] }

      const value = action.payload.length > 1 ?
          action.payload.filter(v => v !== 'all') :
          action.payload
      return { ...state, emailTypeFilter: value }
    }
    default: {
      const matches = /(.*)_(REQ|SUCCESS|ERROR)/.exec(action.type)

      // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
      if (!matches) return state
      const [, requestName, reqActionType] = matches

      return {
        ...state,
        loading: {
          ...state.loading,
          // If the reqActionType is REQ, loading[requestName] will be true
          [requestName]: reqActionType === 'REQ',
        },
      }
    }
  }
}
