import types from './types'
import { handleFetchResp, getHeadersObj, constants } from 'lib'
const { apiUrl } = constants

export const fetchEmailCards = (id) => async (dispatch, getState) => {
  const { display } = getState()
  dispatch({ type: types.EMAILCARDS_FETCH_REQ })

  const url = new URL(`${apiUrl}/v1/pages/email-cards`)
  const params = { from: display.startDate, to: display.endDate, dbID: id }
  url.search = new URLSearchParams(params).toString()

  await fetch(url, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.EMAILCARDS_FETCH_SUCCESS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: types.EMAILCARDS_FETCH_ERROR })
      window.alert(error.message)
    })
}

export const fetchEmailTypes = () => async (dispatch, getState) => {
  const { pages } = getState()
  if (pages.emailTypes.length !== 0) return;

  dispatch({ type: types.EMAILCARD_TYPES_FETCH_REQ })
  const url = new URL(`${apiUrl}/v1/emails/types`)

  await fetch(url, getHeadersObj())
      .then(handleFetchResp(dispatch))
      .then((data) => {
        dispatch({
          type: types.EMAILCARD_TYPES_FETCH_SUCCESS,
          payload: data,
        })
      })
      .catch((error) => {
        dispatch({ type: types.EMAILCARD_TYPES_FETCH_ERROR })
        window.alert(error.message)
      })
}

export const replicateDbEmailCards = (id) => async (dispatch) => {
  dispatch({ type: types.REPLICATE_EMAILCARDS_REQ })

  const url = `${apiUrl}/v1/pages/email-cards/replicate?dbId=${id}`

  await fetch(url, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then(() => {
      dispatch({ type: types.REPLICATE_EMAILCARDS_SUCCESS })
    })
    .catch((error) => {
      dispatch({ type: types.REPLICATE_EMAILCARDS_ERROR })
      window.alert(error.message)
    })
}

export const timeoutStart = () => ({
  type: types.TIMEOUT_REQ,
})

export const timeoutComplete = () => ({
  type: types.TIMEOUT_SUCCESS,
})
