import types from './types'

const initialState = {
  emailTypes: [],
  emailCards: [],
  timeout: null,
}

export default function databaseReducer(state = initialState, action) {
  switch (action.type) {
    case types.EMAILCARD_TYPES_FETCH_SUCCESS: {
      return { ...state, emailTypes: action.payload.types }
    }
    case types.EMAILCARDS_FETCH_SUCCESS: {
      const { cardStats } = action.payload

      return { ...state, emailCards: cardStats }
    }
    default:
      return state
  }
}
