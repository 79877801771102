import { Chip, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { setEmailTypeFilter } from '../store/display/actions';
import { getEmailTypeFilter } from '../store/display/selectors';
import { getEmailTypes } from '../store/pages/selectors';

const useStyles = makeStyles(() => ({
    selectContainer: {
        minWidth: 120,
        display: 'flex',
        flexDirection: 'column'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2
    }
}))

const EmailTypeFilter = ({ onChange }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const emailTypes = useSelector(getEmailTypes)
    const emailTypeFilter = useSelector(getEmailTypeFilter)

    const onOptionChange = (e) => {
        const value = e.target?.value
        dispatch(setEmailTypeFilter(value))
        if (onChange) onChange(value)
    }

    return (
        <>
            <div className={classes.selectContainer}>
                <Select
                    labelId="email-type-label"
                    id="email-type-option"
                    value={emailTypeFilter}
                    onChange={onOptionChange}
                    startAdornment={<InputAdornment position="start">Type</InputAdornment>}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    multiple
                >
                    <MenuItem value="all">All</MenuItem>
                    { emailTypes?.map(type => <MenuItem key={type} value={type}>{type}</MenuItem>) }
                </Select>
            </div>
        </>
    )
}

EmailTypeFilter.propTypes = {
    onChange: PropTypes.func
}

export default EmailTypeFilter
