import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import GetAppIcon from '@material-ui/icons/GetApp';
import debounce from 'lodash.debounce'

import CardsRow from 'components/CardsRow';
import DeviationDialog from 'components/DeviationDialog';
import StatsTable from 'components/stats-table'
import { useHistory } from 'react-router-dom';

import { getEmailTypeFilter, getEndDate, getStartDate } from 'store/display/selectors'
import { fetchClientStats } from 'store/stats/actions'
import { CLIENTSTATS_FETCH } from 'store/stats/types'

import { darkTheme } from 'styles/themeClasses';
import getAverageStats from 'lib/getAverageStats';
import { getHeadersObj, constants } from 'lib'
import 'styles/reloadButton.css'
import store from '../../store';
import { fetchEmailTypes } from '../../store/pages/actions';
import EmailTypeFilter from '../EmailTypeFilter';

const useStyles = makeStyles((theme) => ({
  cardsFiltersWrapper: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  filtersWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  nameFilterWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    marginRight: theme.spacing(1),
    '& :first-child': {
      marginRight: theme.spacing(1),
    },
    '& :last-child': {
      flex: 1,
    }
  },
  nameFilter: {
    width: '100%'
  },
  emailTypeFilterWrapper: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  filtersActionsCardsWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  filtersActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  }
}))

const ByClient = () => {
  const classes = useStyles(darkTheme)
  const history = useHistory()
  const dispatch = useDispatch()

  const clientStats = useSelector((state) => state.stats.clientStats)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const emailType = useSelector(getEmailTypeFilter)

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false)
  const [isExportingData, setIsExportingData] = useState(false)
  const [nameFilter, setNameFilter] = useState('')

  const debouncedNameFilter = useCallback(debounce((name) => {
    setNameFilter(name)
  }, 200), [])
  const onNameFilterChange = (e) => debouncedNameFilter(e.target.value)

  const debouncedExport = useCallback(debounce(async () => {
    setIsExportingData(true)
    const url = new URL(`${constants.apiUrl}/v1/stats/by-client`)
    const params = { from: startDate, to: endDate, type: emailType, export: true }
    url.search = new URLSearchParams(params).toString()

    const response = await fetch(url, getHeadersObj())
        .catch()
        .finally(() => setIsExportingData(false))
    const blob = await response.blob()
    const anchorHref = window.URL.createObjectURL(blob)
    const anchorElement = document.createElement('a')

    anchorElement.href = anchorHref;
    anchorElement.download = response.headers.get('Content-Disposition')
    anchorElement.click()
  }, 200), [startDate, endDate])
  const onExportButtonClick = () => debouncedExport()

  store.dispatch(fetchEmailTypes())
  const fetchData = () => dispatch(fetchClientStats())

  useEffect(fetchData, [startDate, endDate, emailType])

  const tableRows =
    nameFilter === ''
      ? clientStats
      : clientStats.filter((row) => row.name.toLowerCase().includes(nameFilter.toLowerCase()))

  const averageStats = useMemo(
    () => getAverageStats(tableRows.filter(v => v.stats.projectsCount > 0)),
    [tableRows]
  )

  const onEntryDetails = (entry) => () => {
    history.push(`/by-client/${entry.id}`)
  }

  return (
    <>
      <div className={classes.cardsFiltersWrapper}>
        <CardsRow averageStats={averageStats} />
        <div className={classes.filtersWrapper}>
          <div className={classes.nameFilterWrapper}>
            <Search />
            <TextField
                className={classes.nameFilter}
                label="Search"
                onChange={onNameFilterChange}
            />
          </div>
          <div className={classes.emailTypeFilterWrapper}>
            <EmailTypeFilter />
          </div>
          <div className={classes.actions}>
            <Tooltip
                title="Set Allowed Deviation"
                aria-label="Set Allowed Deviation"
            >
              <IconButton onClick={() => setDeviationDialogOpen(true)}>
                <ShuffleIcon />
              </IconButton>
            </Tooltip>
            {isExportingData &&
                <CircularProgress size={30} />
            }
            {!isExportingData &&
                <Tooltip
                    title="Export Data"
                    aria-label="Export Data"
                >
                  <IconButton onClick={onExportButtonClick}>
                    <GetAppIcon />
                  </IconButton>
                </Tooltip>
            }
          </div>
        </div>
      </div>
      <StatsTable
        id="clientStats"
        rows={tableRows}
        averageStats={averageStats}
        type="byClient"
        reloadData={fetchData}
        actionTypes={[CLIENTSTATS_FETCH]}
        onEntryDetails={onEntryDetails}
      />
      <DeviationDialog
        open={deviationDialogOpen}
        onClose={() => setDeviationDialogOpen(false)}
      />
    </>
  )
}

export default ByClient
