import types from './types'

const initialState = {
  clientStats: [],
  clientIdStats: {
    id: '',
    name: '',
    campaigns: [],
    users: []
  },
  userStats: [],
  userIdStats: {
    id: '',
    name: '',
    campaigns: []
  },
  filteredOutUsers: []
}

export default function databaseReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLIENTSTATS_FETCH_SUCCESS: {
      const { cardCountByDB, statsByClient: clients } = action.payload

      const clientStats = []
      clients?.forEach(({ id, name, stats }) => {
        if (stats) {
          stats.projectsCount = cardCountByDB[id] || 0
        }

        clientStats.push({ id, name, stats })
      })

      clientStats.sort((a, b) => a.name?.localeCompare(b.name))

      return { ...state, clientStats }
    }
    case types.CLIENT_ID_STATS_FETCH_SUCCESS:
      return { ...state, clientIdStats: action.payload }
    case types.CLEAR_CLIENT_ID_STATS:
      return { ...state, clientIdStats: initialState.clientIdStats }
    case types.USERSTATS_FETCH_SUCCESS: {
      const users = action.payload

      const userStats = []
      users.forEach(({ id, name, statsList }) => {
        statsList?.forEach(({ prop, cards, stats }) => {
          userStats.push({ name, id, status: prop, cards, stats })
        })
      })

      userStats.sort((a, b) => a.name?.localeCompare(b.name))

      return { ...state, userStats }
    }
    case types.USER_ID_STATS_FETCH_SUCCESS:
      return { ...state, userIdStats: action.payload }
    case types.CLEAR_USER_ID_STATS:
      return { ...state, userIdStats: initialState.userIdStats }
    case types.FILTER_USERS_OUT:
      return { ...state, filteredOutUsers: action.payload }
    default:
      return state
  }
}
