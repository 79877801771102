import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import CachedIcon from '@material-ui/icons/Cached'
import StatsTableFooter from './StatsTableFooter';

import TableHeader from './TableHeader'
import StatsTableBody from './StatsTableBody'

import { createLoadingSelector } from 'store/display/selectors'
import { useStyles, darkTheme } from 'styles/themeClasses'
import { getSortFn } from './'

import 'styles/index.scss'

const tableStyles = makeStyles((theme) => ({
  root: {
    '& 	.MuiTableCell-root': {
      padding: theme.spacing(1),
      fontSize: '0.70rem',
      minWidth: 62,
    },
    '& .MuiTableSortLabel-icon': {
      fontSize: '12px',
      margin: '0 0 0 4px',
      padding: 0,
    },
  },
  tableCellWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const StatsTable = ({
  rows,
  averageStats,
  type,
  reloadData,
  actionTypes = [],
  onEntryDetails,
  onHeaderFilterClick,
}) => {
  const classes = useStyles(darkTheme)
  const tableClasses = tableStyles(darkTheme)

  const [sortBy, setSortBy] = useState('')
  const [order, setOrder] = useState('')

  const isLoading = useSelector(createLoadingSelector(actionTypes))

  if (isLoading && !rows?.length)
    return (
      <div className={classes.center}>
        <CircularProgress size={68} />
      </div>
    )

  const setSort = (prop) => {
    if (order === '') {
      setSortBy(prop)
      setOrder('asc')
    } else if (order === 'asc') {
      setSortBy(prop)
      setOrder('desc')
    } else if (order === 'desc') {
      setSortBy('')
      setOrder('')
    }
  }

  const sortedRows = [...rows]

  if (sortBy) sortedRows.sort(getSortFn(sortBy, order))

  const getSortLabel = (label) => (
    <TableSortLabel
      active={sortBy === label}
      direction={sortBy === label ? order : 'asc'}
    />
  )

  return (
    <Paper className={classes.paper}>
      <IconButton edge="end" aria-label="reload" onClick={reloadData}>
        {isLoading ? <CircularProgress size={24} /> : <CachedIcon />}
      </IconButton>
      <Table classes={{ root: tableClasses.root }} size="small">
        <TableHeader
          onHeaderFilterClick={onHeaderFilterClick}
          getSortLabel={getSortLabel}
          setSort={setSort}
          type={type}
        />
        <StatsTableBody
          sortedRows={sortedRows}
          averageStats={averageStats}
          type={type}
          onEntryDetails={onEntryDetails}
        />
        {averageStats &&
          <StatsTableFooter
            averageStats={averageStats}
            type={type}
          />
        }
      </Table>
    </Paper>
  )
}

StatsTable.propTypes = {
  id: PropTypes.string,
  rows: PropTypes.array,
  averageStats: PropTypes.object,
  type: PropTypes.string,
  reloadData: PropTypes.func,
  actionTypes: PropTypes.array,
  onEntryDetails: PropTypes.func,
  onHeaderFilterClick: PropTypes.func,
}

export default StatsTable
