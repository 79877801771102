import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { CopySection, DesignSection, FinalSection } from './sections'

import { getColsToShow } from 'store/display/selectors'

import 'styles/index.scss'

const nameClass = 'nameColumn'
const defaultClass = 'defaultColumn'

const StatsTableBody = ({ sortedRows, averageStats, type, onEntryDetails }) => {
  const { showCopy, showDesign, showFinal } = useSelector(getColsToShow)

  return (
    <TableBody>
      {sortedRows?.map((row) => {
        const { id, name, status = '', stats, type: entityType } = row

        if (!stats) return

        const { projectsCount, emailsAtCopy } = stats

        return (
          <TableRow key={id + status}>
            <TableCell
              className={nameClass}
              style={{ cursor: onEntryDetails ? 'pointer' : 'default' }}
              onClick={onEntryDetails && onEntryDetails(row)}
            >
              {name}
              {type === 'byUserId' && (
                <>
                  <br />
                  {row?.client?.name}
                </>
              )}
            </TableCell>
            {type === 'byClient' && (
              <TableCell className={defaultClass}>{projectsCount || ''}</TableCell>
            )}
            <TableCell className={defaultClass}>
              {type === 'byClient' ? emailsAtCopy || '' : status}
            </TableCell>
            {['byCampaign', 'byClientId', 'byUserId'].includes(type) && (
              <TableCell className={defaultClass}>{entityType}</TableCell>
            )}
            <CopySection
              stats={stats}
              averageStats={averageStats}
              show={showCopy}
            />
            <DesignSection
              stats={stats}
              averageStats={averageStats}
              show={showDesign}
            />
            <FinalSection
              stats={stats}
              averageStats={averageStats}
              show={showFinal}
            />
          </TableRow>
        )
      })}
    </TableBody>
  )
}

StatsTableBody.propTypes = {
  sortedRows: PropTypes.array,
  averageStats: PropTypes.object,
  type: PropTypes.string,
  onEntryDetails: PropTypes.func,
}

export default StatsTableBody
