import { createActionTypes } from 'lib'

const SIGNIN = 'SIGNIN'
const SIGNUP = 'SIGNUP'

export default {
  ...createActionTypes(SIGNIN),
  ...createActionTypes(SIGNUP),

  IS_LOGGED_IN: 'IS_LOGGED_IN',
}

export { SIGNIN, SIGNUP }
