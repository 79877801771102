import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ShuffleIcon from '@material-ui/icons/Shuffle'

import CardsRow from 'components/CardsRow'
import DeviationDialog from 'components/DeviationDialog'
import StatsTable from 'components/stats-table'
import TypeToggle from 'components/TypeToggle'

import { getEndDate, getStartDate } from 'store/display/selectors'
import { clearUserIdStats, fetchUserIdStats } from 'store/stats/actions'
import getAverageStats from 'lib/getAverageStats'
import { darkTheme } from 'styles/themeClasses'
import { USER_ID_STATS_FETCH } from 'store/stats/types'

const useStyles = makeStyles((theme) => ({
  filtersActionsCardsWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  filtersActionsWrapper: {
    display: 'flex',
    minWidth: 280,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  action2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(1),
  },
  pointer: {
    cursor: 'pointer',
  },
  cardsRowContainer: {
    flex: 1,
  }
}))

const ByUserId = () => {
  const classes = useStyles(darkTheme)

  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const userIdStats = useSelector((state) => state.stats.userIdStats)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false)

  const fetchData = () => dispatch(fetchUserIdStats(id))

  useEffect(fetchData, [startDate, endDate])

  useEffect(() => () => {
    dispatch(clearUserIdStats())
  }, [])

  const typeTooltip = useSelector(state => state.display.typeTooltip)

  const tableRows = useMemo(
    () => userIdStats?.campaigns.filter(({ stats, type }) =>
      !!stats.overallTotal && type.includes(typeTooltip)
    ),
    [userIdStats, typeTooltip]
  )

  const averageStats = useMemo(
    () => getAverageStats(tableRows),
    [tableRows]
  )

  const onEntryDetails = (entry) => () => {
    history.push(`/by-client/${entry?.client?.id}`)
  }

  return (
    <>
      <Grid
        className={classes.filtersActionsCardsWrapper}
        container
        spacing={1}
      >
        <Grid className={classes.filtersActionsWrapper} item>
          <Box className={classes.title}>
            <h1>{userIdStats?.name || '-'}</h1>
          </Box>
          <Box className={classes.actions}>
            <Tooltip
              title="Set Allowed Deviation"
              aria-label="Set Allowed Deviation"
            >
              <IconButton onClick={() => setDeviationDialogOpen(true)}>
                <ShuffleIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <TypeToggle />
        </Grid>
        <Grid item className={classes.cardsRowContainer}>
          <CardsRow averageStats={averageStats} />
        </Grid>
      </Grid>
      <StatsTable
        id="userIdStats"
        rows={tableRows}
        averageStats={averageStats}
        type="byUserId"
        reloadData={fetchData}
        actionTypes={[USER_ID_STATS_FETCH]}
        onEntryDetails={onEntryDetails}
      />
      <DeviationDialog
        open={deviationDialogOpen}
        onClose={() => setDeviationDialogOpen(false)}
      />
    </>
  )
}

export default ByUserId
