import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import {
  CheckBox,
  CheckBoxOutlineBlank as CheckBoxBlank,
} from '@material-ui/icons';

import { filterUsersOut } from 'store/stats/actions'

import stl from './ByUser.module.scss';

const UserSelectionDialog = ({ open, onClose }) => {
  const dispatch = useDispatch()

  const unfilteredUserStats = useSelector(state => state.stats.userStats)
  const filteredOutUsers = useSelector(state => state.stats.filteredOutUsers)

  const onUserClick = (filterId) => {
    let newFOUs

    if (filteredOutUsers.includes(filterId))
      newFOUs = filteredOutUsers.filter(fouId => fouId !== filterId)
    else
      newFOUs = [...filteredOutUsers, filterId]

    dispatch(filterUsersOut(newFOUs))
  }

  const onChangeAllClick = () => {
    let newFOUs = []

    if (!filteredOutUsers.length)
      newFOUs = unfilteredUserStats.map(({ id, status }) => id + status)

    dispatch(filterUsersOut(newFOUs))
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle id="form-dialog-title">Filter users</DialogTitle>
      <DialogContent>
      <List dense>
        {unfilteredUserStats.map(({ id, name, status }) => (
          <ListItem key={id + status}>
            <ListItemIcon
              className={stl.selectionCheckBox}
              onClick={() => onUserClick(id + status)}
            >
              {filteredOutUsers.includes(id + status)
                ? <CheckBoxBlank />
                : <CheckBox />
              }
            </ListItemIcon>
            <ListItemText
              primary={name}
              secondary={status}
            />
          </ListItem>
        ))}
      </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onChangeAllClick}>
          {!filteredOutUsers.length ? 'Deselect all' : 'Select all'}
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

UserSelectionDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default UserSelectionDialog;
