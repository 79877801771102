import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'

import { setDateFilter } from 'store/display/actions'
import { getStartDate, getEndDate } from 'store/display/selectors'

import getDateFiltersHandler from 'lib/getDateFiltersHandler'
import { getQuarterOptions } from 'lib/getQuarterOptions'
import getQuarterFiltersHandler from 'lib/getQuarterFilterHandler'

const useStyles = makeStyles((theme) => ({
  select: {
    minWidth: 100,
    margin: 0,
    marginRight: 15,
    textAlign: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const DateFilter = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { currentQuarter, quarterOptions } = getQuarterOptions()
  const [option, setOption] = useState(2)
  const [quarter, setQuarter] = useState(currentQuarter)

  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)

  const getDateChangeHandler = (dateProp) => (e) => {
    setOption(0)
    dispatch(setDateFilter({ dateProp, dateValue: e.target.value }))
  }

  const setStartAndEndDate = (startDate, endDate) => {
    dispatch(setDateFilter({ dateProp: 'startDate', dateValue: startDate }))
    dispatch(setDateFilter({ dateProp: 'endDate', dateValue: endDate }))
  }

  const onOptionChange = getDateFiltersHandler({
    setOption,
    setStartAndEndDate,
  })
  const onQuarterChange = getQuarterFiltersHandler({
    setQuarter,
    setStartAndEndDate,
  })

  return (
    <>
      <div className={classes.selectContainer}>
        <InputLabel id="date-filter-option" shrink>
          Options
        </InputLabel>
        <Select
          className={classes.select}
          labelId="date-filter-option"
          id="date-filter-option"
          value={option}
          onChange={onOptionChange}
        >
          <MenuItem value={0}>None</MenuItem>
          <MenuItem value={1}>1 day</MenuItem>
          <MenuItem value={2}>30 days</MenuItem>
          <MenuItem value={3}>Quarter</MenuItem>
          <MenuItem value={4}>All time</MenuItem>
        </Select>
      </div>
      {option === 3 && (
        <div className={classes.selectContainer}>
          <InputLabel id="quarter-option" shrink>
            Quarter
          </InputLabel>
          <Select
            className={classes.select}
            labelId="quarter-option"
            id="quarter-option"
            value={quarter}
            onChange={onQuarterChange}
          >
            {quarterOptions.map(({ value, label }) => (
              <MenuItem value={value} key={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <TextField
        id="startDate"
        label="From"
        type="date"
        style={{ marginRight: 15 }}
        value={startDate}
        onChange={getDateChangeHandler('startDate')}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="endDate"
        label="To"
        type="date"
        value={endDate}
        onChange={getDateChangeHandler('endDate')}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  )
}

export default DateFilter
