import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import Routes from './Routes'

import { useStyles, darkTheme } from 'styles/themeClasses'

const App = () => {
  const classes = useStyles(darkTheme)

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.root}>
        <Router>
          <CssBaseline />
          <Routes />
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
