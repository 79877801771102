import StatsTable from './StatsTable'

const getSortFn = (sortBy, order) => (a, b) => {
  const orderMult = order === 'asc' ? 1 : -1
  const { stats: aStats, status: aStatus, name: aName, type: aType } = a
  const { stats: bStats, status: bStatus, name: bName, type: bType } = b
  let comparison = 0

  if (sortBy === 'status') {
    comparison = aStatus?.localeCompare(bStatus)
  } else if (sortBy === 'name') {
    comparison = aName?.localeCompare(bName)
  } else if (sortBy === 'type') {
    comparison = aType?.localeCompare(bType)
  } else if (!bStats[sortBy]) {
    comparison = -1 * orderMult
  } else if (!aStats[sortBy]) {
    comparison = 1 * orderMult
  } else {
    comparison = aStats[sortBy] - bStats[sortBy]
  }

  return comparison * orderMult
}

export default StatsTable
export { getSortFn }
