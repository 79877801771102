import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'

import { getTableColsDisplay } from 'store/display/selectors'
import { setTableColumnsDisplay } from 'store/display/actions'

import StatsCard from './StatsCard'

const CardsRow = ({ averageStats }) => {
  const dispatch = useDispatch()
  const tableColumnsDisplay = useSelector(getTableColsDisplay)

  const getOnShowHideClick = type => () => {
    const isSelected = tableColumnsDisplay.includes(type)
    let newTCDisplay

    if (isSelected) {
      newTCDisplay = tableColumnsDisplay.filter(col => col !== type)
    } else {
      newTCDisplay = [...tableColumnsDisplay, type]
    }

    dispatch(setTableColumnsDisplay(newTCDisplay))
  }

  return (
    <Grid container spacing={1}>
      <Grid item>
        <StatsCard
          name={'Avg. Time for Success'}
          value={averageStats?.averageMsToTime}
          icon={'av_timer'}
          iconColor={'#ffffff'}
          selected
        />
      </Grid>
      <Grid item>
        <StatsCard
          name={'Copy Team Avg.'}
          value={averageStats?.copyTeam?.averageMsToTime}
          icon={'groups'}
          iconColor={'#4eefff'}
          selected={tableColumnsDisplay.includes('copy')}
          onShowHideClick={getOnShowHideClick('copy')}
        />
      </Grid>
      <Grid item>
        <StatsCard
          name={'Design Team Avg.'}
          value={averageStats?.designTeam?.averageMsToTime}
          icon={'groups'}
          iconColor={'#ffaa3a'}
          selected={tableColumnsDisplay.includes('design')}
          onShowHideClick={getOnShowHideClick('design')}
        />
      </Grid>
      <Grid item>
        <StatsCard
          name={'Final Avg.'}
          value={averageStats?.implQlty?.averageMsToTime}
          icon={'done_all'}
          iconColor={'#64ff64'}
          selected={tableColumnsDisplay.includes('final')}
          onShowHideClick={getOnShowHideClick('final')}
        />
      </Grid>
    </Grid>
  )
}

CardsRow.propTypes = {
  averageStats: PropTypes.object,
}

export default CardsRow
