const getQuarterStartDate = (quarter) => {
  const today = new Date()
  const year = today.getFullYear()

  switch (quarter) {
    case 1: {
      return new Date(year, 0, 1)
    }
    case 2: {
      return new Date(year, 3, 1)
    }
    case 3: {
      return new Date(year, 6, 1)
    }
    case 4: {
      return new Date(year, 9, 1)
    }
    default: {
      throw new Error('Invalid quarter value')
    }
  }
}

export default getQuarterStartDate
