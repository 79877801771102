import { subDays, format } from 'date-fns'
import { getCurrentQuarter } from './getQuarterOptions'
import getQuarterStartDate from './getQuarterStartDate'

const formatStr = 'yyyy-MM-dd'

const getDateFiltersHandler =
  ({ setOption, setStartAndEndDate }) =>
  (e) => {
    const value = e.target?.value

    const formatAndSetDates = (startDate, endDate) =>
      setStartAndEndDate(
        format(startDate, formatStr),
        format(endDate, formatStr)
      )

    setOption(value)

    switch (value) {
      case 1: {
        // 1 day
        const endDate = new Date()
        const startDate = subDays(endDate, 1)

        formatAndSetDates(startDate, endDate)

        break
      }
      case 2: {
        // 30 days
        const endDate = new Date()
        const startDate = subDays(endDate, 30)

        formatAndSetDates(startDate, endDate)
        break
      }
      case 3: {
        // Quarter
        const quarter = getCurrentQuarter()
        const endDate = new Date()
        const startDate = getQuarterStartDate(quarter)

        formatAndSetDates(startDate, endDate)
        break
      }
      case 4: {
        // All time
        const endDate = new Date()
        setStartAndEndDate('', format(endDate, formatStr))
        break
      }
      default:
        setOption(0)
    }
  }

export default getDateFiltersHandler
