import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from '@material-ui/core/List'
import { ListItem, ListItemSecondaryAction } from '@material-ui/core'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import FlowTable from './FlowTable'
import TypeToggle from 'components/TypeToggle'

import { useStyles, darkTheme } from 'styles/themeClasses'
import { listDatabases } from 'store/databases/actions'
import { DATABASES_FETCH } from 'store/databases/types'
import {
  createLoadingSelector,
  getEndDate,
  getStartDate,
} from 'store/display/selectors'

const ByCampaign = () => {
  const classes = useStyles(darkTheme)

  const dispatch = useDispatch()
  const [expandedDBs, setExpandedDBs] = useState([])
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const dbList = useSelector((state) => state.databases.dbList)
  const isLoading = useSelector(createLoadingSelector([DATABASES_FETCH]))

  useEffect(() => {
    dispatch(listDatabases())
  }, [])

  useEffect(() => setExpandedDBs([]), [startDate, endDate])

  const handleClick = (id) => {
    let newExpDBs = []

    if (expandedDBs.includes(id)) {
      newExpDBs = expandedDBs.filter((dbID) => dbID !== id)
    } else {
      newExpDBs = [...expandedDBs, id]
    }

    setExpandedDBs(newExpDBs)
  }

  if (isLoading)
    return (
      <div className={classes.center}>
        <CircularProgress size={68} />
        <Typography className={classes.loadingMsg} color="textSecondary">
          Loading databases
        </Typography>
      </div>
    )

  return (
    <>
      <List dense style={{ width: '100%' }}>
        {dbList?.map(({ id, title }) => {
          const expanded = expandedDBs.includes(id)

          return (
            <div key={id}>
              <ListItem button onClick={() => handleClick(id)}>
                <ListItemText primary={title[0]?.plain_text || 'No title'} />
                {expanded && (
                  <ListItemSecondaryAction>
                    <TypeToggle />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              {expanded && <FlowTable dbID={id} expanded={expanded} />}
            </div>
          )
        })}
      </List>
    </>
  )
}

export default ByCampaign
