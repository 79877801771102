import prettyMilliseconds from 'pretty-ms'

export default function msToTime(duration) {
  if (!duration || duration === 0) return 0;

  return prettyMilliseconds(duration, {
    secondsDecimalDigits: 0,
    unitCount: 2,
  }).replace(/\s/g, '')
}
