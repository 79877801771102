import { createActionTypes } from 'lib'

const EMAILCARD_TYPES_FETCH = 'EMAILCARD_TYPES_FETCH'
const EMAILCARDS_FETCH = 'EMAILCARDS_FETCH'
const REPLICATE_EMAILCARDS = 'REPLICATE_EMAILCARDS'
const TIMEOUT = 'TIMEOUT'

export default {
  ...createActionTypes(EMAILCARD_TYPES_FETCH),
  ...createActionTypes(EMAILCARDS_FETCH),
  ...createActionTypes(REPLICATE_EMAILCARDS),
  ...createActionTypes(TIMEOUT),
}

export { EMAILCARD_TYPES_FETCH, EMAILCARDS_FETCH, REPLICATE_EMAILCARDS, TIMEOUT }
