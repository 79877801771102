/* eslint react/display-name: 0 */
/* eslint react/prop-types: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList';

import { getColsToShow } from 'store/display/selectors'

import 'styles/index.scss'

const copyClass = 'copyStatusColumns'
const designClass = 'designStatusColumns'
const finalClass = 'finalColumns'

const getTitle = (type) => {
  switch (type) {
    case 'byClient':
      return 'Clients'
    case 'byClientId':
      return 'Campaigns / Users'
    case 'byCampaign':
      return 'Campaigns'
    case 'byUser':
      return 'Users'
    case 'byUserId':
      return 'Campaign / Client'
    default:
      return ''
  }
}

const TableHeader = ({ onHeaderFilterClick, setSort, getSortLabel, type }) => {
  const { showCopy, showDesign, showFinal } = useSelector(getColsToShow)

  const getTableCell =
    (className = '') =>
    ({ title, prop, tooltip }) =>
      (
        <Tooltip key={prop} title={tooltip} placement="top-start">
          <TableCell
            onClick={() => setSort(prop)}
            className={clsx('actionCell', className)}
          >
            {title}
            {getSortLabel(prop)}
          </TableCell>
        </Tooltip>
      )

  return (
    <TableHead>
      <TableRow>
        <TableCell
          onClick={onHeaderFilterClick}
          colSpan={['byClient', 'byClientId', 'byUserId', 'byCampaign'].includes(type) ? 3 : 2}
        >
          {getTitle(type)}
          {onHeaderFilterClick && <FilterListIcon className="headerFilterIcon" />}
        </TableCell>
        {showCopy && (
          <TableCell align="center" colSpan={5} className={copyClass}>
            COPY
          </TableCell>
        )}
        {showDesign && (
          <TableCell align="center" colSpan={5} className={designClass}>
            DESIGN
          </TableCell>
        )}
        {showFinal && (
          <TableCell align="center" colSpan={5}>
            FINAL
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <Tooltip key={'name'} title={'Row name'} placement="top-start">
          <TableCell onClick={() => setSort('name')} className={clsx('actionCell', 'titleCell')}>
            Name
            {getSortLabel('name')}
          </TableCell>
        </Tooltip>
        {getFirstCells(type).map(getTableCell())}
        {showCopy && copyCells.map(getTableCell(copyClass))}
        {showDesign && designCells.map(getTableCell(designClass))}
        {showFinal && finalCells.map(getTableCell(finalClass))}
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  getSortLabel: PropTypes.func.isRequired,
  onHeaderFilterClick: PropTypes.func,
  setSort: PropTypes.func.isRequired,
  type: PropTypes.string,
}

const getFirstCells = (type) => {
  const firstCells = []

  if (type === 'byClient') {
    const projectsCount = {
      title: 'Prjs',
      prop: 'projectsCount',
      tooltip: 'Total projects'
    }
    firstCells.push(projectsCount)

    const atCopy = {
      title: 'At Cop',
      prop: 'emailsAtCopy',
      tooltip: `Total emails on 'Copy'`,
    }
    firstCells.push(atCopy)
  } else if (type !== 'byUser') {
    const status = { title: 'Status', prop: 'status', tooltip: 'Row status' }
    firstCells.push(status)
  } else {
    const role = { title: 'Role', prop: 'status', tooltip: 'User role' }
    firstCells.push(role)
  }

  if (['byCampaign', 'byClientId', 'byUserId'].includes(type)) {
    const typeCol = { title: 'Type', prop: 'type', tooltip: 'Entity type' }
    firstCells.push(typeCol)
  }

  return firstCells
}

const copyCells = [
  { title: 'Cop', prop: 'copy', tooltip: `On 'Copy' status` },
  { title: 'Rev', prop: 'copyRev', tooltip: 'On Copy Revision' },
  { title: 'App', prop: 'copyApp', tooltip: 'On Copy Approval' },
  { title: 'ATR', prop: 'CopyATR', tooltip: `Copy Approval to Revision count` },
  { title: 'Tot', prop: 'copyTotal', tooltip: 'Total On Copy Team' },
]

const designCells = [
  { title: 'Des', prop: 'design', tooltip: `On 'Design' status` },
  { title: 'Rev', prop: 'designRev', tooltip: 'On Design Revision' },
  { title: 'App', prop: 'designApp', tooltip: 'On Design Approval' },
  {
    title: 'ATR',
    prop: 'DesignATR',
    tooltip: `Design Approval to Revision count`,
  },
  { title: 'Tot', prop: 'designTotal', tooltip: 'Total On Design Team' },
]

const finalCells = [
  { title: 'Imp', prop: 'impl', tooltip: 'On Implementation' },
  { title: 'QC', prop: 'qc', tooltip: 'On Quality Check' },
  { title: 'F. QC', prop: 'fqc', tooltip: 'On Final Quality Check' },
  { title: 'Bicoe', prop: 'bicoe', tooltip: 'How many times on type BICOE' },
  { title: 'Tot', prop: 'overallTotal', tooltip: 'Overall Total' },
]

export default TableHeader
