import handleFetchResp from './handleFetchResp'
import msToTime from './msToTime'
import constants from './constants'

export {
  msToTime,
  constants,
  handleFetchResp,
}

export const getHeadersObj = () => ({
  headers: getAuthHeader(),
})

export const getAuthHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem('jwt')}`,
})

export const createActionTypes = (action) => ({
  [`${action}_REQ`]: `${action}_REQ`,
  [`${action}_SUCCESS`]: `${action}_SUCCESS`,
  [`${action}_ERROR`]: `${action}_ERROR`,
})
