import { toggleLoggedStatus } from 'store/users/actions'

const handleFetchResp = (dispatch) => async (resp) => {
  if (resp.status === 401) {
    setTimeout(() => {
      const toggleLSAction = toggleLoggedStatus(true)
      toggleLSAction(dispatch)
    }, 3000)
  }

  if (!resp.ok) {
    const serverResp = await resp.json()

    throw new Error(`Server message: ${serverResp.message}`)
  }

  const newToken = resp.headers.get('token')
  if (newToken) {
    localStorage.setItem('jwt', newToken)
  }

  return resp.json()
}

export default handleFetchResp
