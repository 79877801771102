import React from 'react'
import PropTypes from 'prop-types'
import { TableFooter } from '@material-ui/core'

import TableFooterRow from './TableFooterRow'

const StatsTableFooter = ({ averageStats, type }) => {
  if (!averageStats) return null

  return (
    <TableFooter
      style={{ backgroundColor: '#3b3b3c', borderTop: '3px solid #515151' }}
    >
      <TableFooterRow
        averageStats={averageStats}
        rowProp='count'
        rowTitle='Count'
        type={type}
      />
      <TableFooterRow
        averageStats={averageStats}
        rowProp='totalMsToTime'
        rowTitle='Total'
        type={type}
      />
      <TableFooterRow
        averageStats={averageStats}
        rowProp='averageMsToTime'
        rowTitle='Average'
        type={type}
      />
    </TableFooter>
  )
}

StatsTableFooter.propTypes = {
  averageStats: PropTypes.object,
  type: PropTypes.string
}

export default StatsTableFooter
