import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell'

import DeviationFlag from './DeviationFlag'

import { msToTime } from 'lib'

const StatsTableCell = ({ value, className, average, tolerance, isComplete }) => (
  <TableCell className={className}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {value ? msToTime(value) : ''}
      {isComplete && (
        <DeviationFlag
          value={value}
          average={average}
          tolerance={tolerance}
          type={'days'}
        />
      )}
    </div>
  </TableCell>
);

StatsTableCell.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  average: PropTypes.number,
  tolerance: PropTypes.number,
  isComplete: PropTypes.any,
};

export default StatsTableCell;
