import types from './types'
import { handleFetchResp, getHeadersObj, constants } from 'lib'
const { apiUrl } = constants

export const fetchClientStats = () => async (dispatch, getState) => {
  const state = getState()
  dispatch({ type: types.CLIENTSTATS_FETCH_REQ })

  const url = new URL(`${apiUrl}/v1/stats/by-client`)
  const params = {
    from: state.display.startDate,
    to: state.display.endDate,
    type: state.display.emailTypeFilter
  }
  url.search = new URLSearchParams(params).toString()

  await fetch(url, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then((data) => {
      if (!data?.statsByClient)
        throw new Error('Server error, please try again later')

      dispatch({
        type: types.CLIENTSTATS_FETCH_SUCCESS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: types.CLIENTSTATS_FETCH_ERROR })
      window.alert(error.message)
    })
}

export const fetchClientIdStats = (clientId) => async (dispatch, getState) => {
  const state = getState()
  dispatch({ type: types.CLIENT_ID_STATS_FETCH_REQ })

  const url = new URL(`${apiUrl}/v1/stats/by-client/${clientId}`)
  const params = {
    from: state.display.startDate,
    to: state.display.endDate,
    type: state.display.emailTypeFilter
  }
  url.search = new URLSearchParams(params).toString()

  await fetch(url, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.CLIENT_ID_STATS_FETCH_SUCCESS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: types.CLIENT_ID_STATS_FETCH_ERROR })
      window.alert(error.message)
    })
}

export const clearClientIdStats = () => ({
  type: types.CLEAR_CLIENT_ID_STATS,
})

export const fetchUserStats = () => async (dispatch, getState) => {
  const state = getState()
  dispatch({ type: types.USERSTATS_FETCH_REQ })

  const url = new URL(`${apiUrl}/v1/stats/by-user`)
  const params = { from: state.display.startDate, to: state.display.endDate }
  url.search = new URLSearchParams(params).toString()

  await fetch(url, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then(({ userStats }) => {
      if (!userStats)
        throw new Error({
          message: 'Server error, please try again later',
        })

      dispatch({
        type: types.USERSTATS_FETCH_SUCCESS,
        payload: userStats,
      })
    })
    .catch((error) => {
      dispatch({ type: types.USERSTATS_FETCH_ERROR })
      window.alert(error.message)
    })
}

export const fetchUserIdStats = (userId) => async (dispatch, getState) => {
  const state = getState()
  dispatch({ type: types.USER_ID_STATS_FETCH_REQ })

  const url = new URL(`${apiUrl}/v1/stats/by-user/${userId}`)
  const params = { from: state.display.startDate, to: state.display.endDate }
  url.search = new URLSearchParams(params).toString()

  await fetch(url, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.USER_ID_STATS_FETCH_SUCCESS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: types.USER_ID_STATS_FETCH_ERROR })
      window.alert(error.message)
    })
}

export const clearUserIdStats = () => ({
  type: types.CLEAR_USER_ID_STATS,
})

export const filterUsersOut = (users) => ({
  type: types.FILTER_USERS_OUT,
  payload: users,
})
