import types from './types'
import { handleFetchResp, constants } from 'lib'
const { apiUrl } = constants

export const signUp = (userData, history) => async (dispatch) => {
  dispatch({ type: types.SIGNUP_REQ })

  await fetch(`${apiUrl}/v1/users/sign-up`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(userData),
  })
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.SIGNUP_SUCCESS,
        payload: data,
      })

      history.push('/')
    })
    .catch((error) => {
      dispatch({ type: types.SIGNUP_ERROR })
      window.alert(error.message)
    })
}

export const signIn = (userData, history) => async (dispatch) => {
  dispatch({ type: types.SIGNIN_REQ })

  await fetch(`${apiUrl}/v1/users/sign-in`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(userData),
  })
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.SIGNIN_SUCCESS,
        payload: data,
      })
      history.push('/')
    })
    .catch((error) => {
      dispatch({ type: types.SIGNIN_ERROR })
      window.alert(error.message)
    })
}

export const toggleLoggedStatus = (shouldLogout) => async (dispatch) => {
  if (shouldLogout) localStorage.setItem('jwt', '')

  dispatch({ type: types.IS_LOGGED_IN })
}
