import { configureStore } from '@reduxjs/toolkit'

import databaseReducer from './databases/reducer'
import pagesReducer from './pages/reducer'
import statsReducer from './stats/reducer'
import userReducer from './users/reducer'
import displayReducer from './display/reducer'

export default configureStore({
  reducer: {
    databases: databaseReducer,
    display: displayReducer,
    pages: pagesReducer,
    stats: statsReducer,
    user: userReducer,
  },
})
