import types from './types'

const initialState = {
  dbList: [],
  content: [],
}

export default function databaseReducer(state = initialState, action) {
  switch (action.type) {
    case types.DATABASES_FETCH_SUCCESS:
      return { ...state, dbList: action.payload }
    case types.DATABASES_QUERY_SUCCESS:
      return { ...state, content: action.payload }
    default:
      return state
  }
}
