import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { darkTheme } from 'styles/themeClasses'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2)
  },
  table: {
    '& 	.MuiTableCell-root': {
      padding: theme.spacing(1),
      fontSize: '0.70rem',
    },
    '& .MuiTableSortLabel-icon': {
      fontSize: '12px',
      margin: '0 0 0 4px',
      padding: 0,
    },
  }
}))

const UsersTable = ({ users, onEntryDetails }) => {
  const classes = useStyles(darkTheme)

  return (
    <Paper classes={{root: classes.paper}}>
      <Table classes={{ root: classes.table }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow key={user?.id}>
              <TableCell
                style={{ cursor: onEntryDetails ? 'pointer' : 'default' }}
                onClick={onEntryDetails && onEntryDetails(user)}
              >
                {user?.name}
              </TableCell>
              <TableCell>{user?.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

UsersTable.propTypes = {
  users: PropTypes.array,
  onEntryDetails: PropTypes.func,
}

export default UsersTable
