import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { getColsToShow } from 'store/display/selectors'

const TableFooterRow = ({ averageStats, rowProp, rowTitle, type }) => {
  const { copyTeam, designTeam, implQlty } = averageStats
  const { copy, copyRev, copyApp, CopyATR } = copyTeam || {}
  const { design, designRev, designApp, DesignATR } = designTeam || {}
  const { impl, qc, fqc, bicoe } = implQlty || {}

  const { showCopy, showDesign, showFinal } = useSelector(getColsToShow)

  const getCopyElements = () => (
    <>
      {[copy, copyRev, copyApp].map((obj) => getTableCell(rowProp, obj || {}))}
      <TableCell>{CopyATR[rowProp.replace('MsToTime', '')]}</TableCell>
      <TableCell>{copyTeam[rowProp]}</TableCell>
    </>
  )

  const getDesignElements = () => (
    <>
      {[design, designRev, designApp].map((obj) =>
        getTableCell(rowProp, obj || {})
      )}
      <TableCell>{DesignATR[rowProp.replace('MsToTime', '')]}</TableCell>
      <TableCell>{designTeam[rowProp]}</TableCell>
    </>
  )

  const getFinalElements = () => (
    <>
      {[impl, qc, fqc].map((obj) => getTableCell(rowProp, obj || {}))}
      <TableCell>{bicoe[rowProp.replace('MsToTime', '')]}</TableCell>
      <TableCell>{averageStats[rowProp]}</TableCell>
    </>
  )

  return (
    <TableRow>
      {rowTitle === 'Count' && (
        <TableCell
          rowSpan={['byClientId', 'byUserId'].includes(type) ? 4 : 3}
          style={{ backgroundColor: '#424242', borderBottom: 'none' }}
        />
      )}
      <TableCell
        colSpan={['byClient', 'byClientId', 'byCampaign', 'byUserId'].includes(type) ? 2 : 1}
        style={{
          backgroundColor: '#424242',
          borderBottom: 'none',
          textAlign: 'right',
        }}
      >
        <h3>{rowTitle}:</h3>
      </TableCell>
      {showCopy && getCopyElements()}
      {showDesign && getDesignElements()}
      {showFinal && getFinalElements()}
    </TableRow>
  )
}

const getTableCell = (propName, obj) => (
  <TableCell key={obj?.total || Math.random()}>{obj[propName]}</TableCell>
)

TableFooterRow.propTypes = {
  averageStats: PropTypes.object,
  rowProp: PropTypes.string,
  rowTitle: PropTypes.string,
  type: PropTypes.string,
};

export default TableFooterRow;
