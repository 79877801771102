import { format } from 'date-fns'

const getCurrentQuarter = () => {
  const today = new Date()
  return Math.floor((today.getMonth() + 3) / 3)
}

const getOrdinal = (n) =>
  ['', 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th'

const getQuarterOptions = () => {
  const currentQuarter = getCurrentQuarter()

  const optionsRaw = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]

  const year = format(new Date(), 'yy')

  const quarterOptions = optionsRaw
    .map(({ value }) => {
      const ordinal = getOrdinal(value)
      let optYear = year
      let index = value

      if (value > +currentQuarter) {
        optYear = +year - 1
        index -= 4
      }

      return { value, label: `${optYear}/${value}${ordinal}`, index }
    })
    .sort((a, b) => a.index - b.index)

  return {
    currentQuarter,
    quarterOptions,
  }
}

export { getQuarterOptions, getCurrentQuarter }
