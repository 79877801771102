import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'

import StatsTableCell from './StatsTableCell'
import DeviationFlag from './DeviationFlag'

import { getAllowedDeviation } from 'store/display/selectors'

const copyClass = 'copyStatusColumns'
const totalCopyClass = 'totalCopyColumn'

const CopySection = ({ stats, averageStats, show }) => {
  const allowedDeviation = useSelector(getAllowedDeviation)

  if (!show) return null

  const {
    copy,
    copyRev,
    copyApp,
    CopyATR: copyATR,
    copyTotal,
    designTotal,
    impl,
    qc,
    fqc,
  } = stats

  const hasFinalValue = impl || qc || fqc

  return (
    <>
      <StatsTableCell value={copy} className={copyClass} />
      <StatsTableCell value={copyRev} className={copyClass} />
      <StatsTableCell value={copyApp} className={copyClass} />
      <TableCell className={copyClass}>
        {copyATR || ''}
        {!!copyATR && copyTotal && (
          <DeviationFlag
            value={copyATR}
            average={averageStats?.copyTeam?.CopyATR?.average}
            tolerance={allowedDeviation.atr}
          />
        )}
      </TableCell>
      <StatsTableCell
        value={copyTotal}
        className={totalCopyClass}
        average={averageStats?.copyTeam?.average}
        tolerance={allowedDeviation.copyTeam}
        isComplete={designTotal || hasFinalValue}
      />
    </>
  );
}

CopySection.propTypes = {
  show: PropTypes.bool,
  stats: PropTypes.object,
  averageStats: PropTypes.object,
};

export default CopySection;
