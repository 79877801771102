import { makeStyles } from '@material-ui/core/styles'

import ByUser from './ByUser'
import UserSelectionDialog from './UserSelectionDialog'
import useByUserEffects from './useByUserEffects'

export default ByUser
export { UserSelectionDialog, useByUserEffects }

export const useStyles = makeStyles((theme) => ({
  filtersActionsCardsWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  filtersActionsWrapper: {
    display: 'flex',
    minWidth: 280,
  },
  roleFilter: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  cardsRowContainer: {
    flex: 1,
  },
}))
