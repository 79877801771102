import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAllowedDeviation } from '../store/display/actions'
import { getAllowedDeviation } from '../store/display/selectors'
import { darkTheme } from '../styles/themeClasses'

const dayMarks = Array.from({ length: 6 }, (v, i) => ({
  value: i * 2,
  label: i * 2 + ' Days',
}))
const countMarks = Array.from({ length: 6 }, (v, i) => ({
  value: i * 2,
  label: i * 2,
}))

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    margin: theme.spacing(4),
  },
}))

const DeviationDialog = ({ open, onClose }) => {
  const classes = useStyles(darkTheme)

  const allowedDeviation = useSelector(getAllowedDeviation)
  const dispatch = useDispatch()

  const [copyTeamDeviation, setCopyTeamDeviation] = useState(
    allowedDeviation.copyTeam
  )
  const [designTeamDeviation, setDesignTeamDeviation] = useState(
    allowedDeviation.designTeam
  )
  const [overallDeviation, setOverallDeviation] = useState(
    allowedDeviation.overall
  )
  const [atrDeviation, setAtrDeviation] = useState(allowedDeviation.atr)
  const [bicoeDeviation, setBicoeDeviation] = useState(allowedDeviation.bicoe)

  const onSave = () => {
    const payload = {
      copyTeam: copyTeamDeviation,
      designTeam: designTeamDeviation,
      overall: overallDeviation,
      atr: atrDeviation,
      bicoe: bicoeDeviation,
    }

    dispatch(setAllowedDeviation(payload))

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle id="form-dialog-title">Set Allowed Deviation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Allowed metric deviation from average before showing flag.
        </DialogContentText>
        <Box className={classes.sliderWrapper}>
          <Typography id="copy-team-deviation" gutterBottom>
            Copy Team
          </Typography>
          <Slider
            value={copyTeamDeviation}
            onChange={(e, v) => setCopyTeamDeviation(v)}
            aria-labelledby="copy-team-deviation"
            valueLabelDisplay="auto"
            marks={dayMarks}
            step={1}
            min={0}
            max={10}
          />
        </Box>
        <Box className={classes.sliderWrapper}>
          <Typography id="design-team-deviation" gutterBottom>
            Design Team
          </Typography>
          <Slider
            value={designTeamDeviation}
            onChange={(e, v) => setDesignTeamDeviation(v)}
            aria-labelledby="design-team-deviation"
            valueLabelDisplay="auto"
            marks={dayMarks}
            step={1}
            min={0}
            max={10}
          />
        </Box>
        <Box className={classes.sliderWrapper}>
          <Typography id="atr-deviation" gutterBottom>
            ATR
          </Typography>
          <Slider
            value={atrDeviation}
            onChange={(e, v) => setAtrDeviation(v)}
            aria-labelledby="atr-deviation"
            valueLabelDisplay="auto"
            marks={countMarks}
            step={1}
            min={0}
            max={10}
          />
        </Box>
        <Box className={classes.sliderWrapper}>
          <Typography id="bicoe-deviation" gutterBottom>
            BICOE
          </Typography>
          <Slider
            value={bicoeDeviation}
            onChange={(e, v) => setBicoeDeviation(v)}
            aria-labelledby="bicoe-deviation"
            valueLabelDisplay="auto"
            marks={countMarks}
            step={1}
            min={0}
            max={10}
          />
        </Box>
        <Box className={classes.sliderWrapper}>
          <Typography id="overall-deviation" gutterBottom>
            Overall
          </Typography>
          <Slider
            value={overallDeviation}
            onChange={(e, v) => setOverallDeviation(v)}
            aria-labelledby="overall-deviation"
            valueLabelDisplay="auto"
            marks={dayMarks}
            step={1}
            min={0}
            max={10}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave} color={'primary'}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeviationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default DeviationDialog
