import { createActionTypes } from 'lib'

const DATABASES_FETCH = 'DATABASES_FETCH'
const DATABASES_QUERY = 'DATABASES_QUERY'

export default {
  ...createActionTypes(DATABASES_FETCH),
  ...createActionTypes(DATABASES_QUERY),
}

export { DATABASES_FETCH, DATABASES_QUERY }
