import types from './types'

export const setDateFilter = (payload) => ({
  type: types.SET_DATE_FILTER,
  payload,
})

export const setAllowedDeviation = (payload) => ({
  type: types.SET_ALLOWED_DEVIATION,
  payload,
})

export const setTableColumnsDisplay = (payload) => ({
  type: types.SET_TABLE_COLS_DISPLAY,
  payload,
})

export const setEmailTypeFilter = (payload) => ({
  type: types.SET_EMAIL_TYPE_FILTER,
  payload,
})