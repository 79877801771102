import types from './types'
import { handleFetchResp, getHeadersObj, getAuthHeader, constants } from 'lib'
const { apiUrl } = constants

export const listDatabases = () => async (dispatch) => {
  dispatch({ type: types.DATABASES_FETCH_REQ })

  await fetch(`${apiUrl}/v1/databases/email-flow-dbs`, getHeadersObj())
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.DATABASES_FETCH_SUCCESS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: types.DATABASES_FETCH_ERROR })
      window.alert(error.message)
    })
}

export const queryDatabase = () => async (dispatch, getState) => {
  const dbList = getState()?.databases?.dbList

  if (!(dbList?.length > 0)) {
    window.alert('Error querying databases')
    return
  }

  const idList = dbList.map((db) => db.id)

  const filter = {
    and: [
      {
        property: 'Status',
        select: {
          does_not_equal: 'Live Flows',
        },
      },
      {
        property: 'Type',
        multi_select: {
          contains: 'Flow',
        },
      },
    ],
  }

  dispatch({ type: types.DATABASES_QUERY_REQ })

  await fetch(`${apiUrl}/v1/databases/query`, {
    method: 'POST',
    body: JSON.stringify({ idList, filter }),
    headers: {
      'Content-type': 'application/json',
      ...getAuthHeader(),
    },
  })
    .then(handleFetchResp(dispatch))
    .then((data) => {
      dispatch({
        type: types.DATABASES_QUERY_SUCCESS,
        payload: data,
      })
    })
    .catch((error) => {
      dispatch({ type: types.DATABASES_QUERY_ERROR })
      window.alert(error.message)
    })
}
