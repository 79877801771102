import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import DateFilter from 'components/DateFilter'

import { useStyles, darkTheme } from 'styles/themeClasses'

import { toggleLoggedStatus } from 'store/users/actions'

function getTitle(path) {
  if (path.startsWith('/by-client')) {
    return 'By Client'
  }

  if (path.startsWith('/by-user')) {
    return 'By User'
  }

  switch (path) {
    case '/campaigns':
      return 'By Campaign'
    default:
      return 'Dashboard'
  }
}

const Topbar = ({ handleDrawerOpen, open }) => {
  const dispatch = useDispatch()
  const classes = useStyles(darkTheme)
  const { pathname } = useLocation()

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Structured Metrics - {getTitle(pathname)}
        </Typography>
        <DateFilter />
        <IconButton
          onClick={() => dispatch(toggleLoggedStatus(true))}
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  handleDrawerOpen: PropTypes.func,
  classes: PropTypes.object,
  open: PropTypes.bool,
}

export default Topbar
