import { createActionTypes } from 'lib'

const CLIENTSTATS_FETCH = 'CLIENTSTATS_FETCH'
const CLIENT_ID_STATS_FETCH = 'CLIENT_ID_STATS_FETCH'
const CLEAR_CLIENT_ID_STATS = 'CLEAR_CLIENT_ID_STATS'
const USERSTATS_FETCH = 'USERSTATS_FETCH'
const USER_ID_STATS_FETCH = 'USER_ID_STATS_FETCH'
const CLEAR_USER_ID_STATS = 'CLEAR_USER_ID_STATS'
const FILTER_USERS_OUT = 'FILTER_USERS_OUT'

export default {
  ...createActionTypes(CLIENTSTATS_FETCH),
  ...createActionTypes(CLIENT_ID_STATS_FETCH),
  CLEAR_CLIENT_ID_STATS,
  ...createActionTypes(USERSTATS_FETCH),
  ...createActionTypes(USER_ID_STATS_FETCH),
  CLEAR_USER_ID_STATS,
  FILTER_USERS_OUT,
}

export { CLIENTSTATS_FETCH, CLIENT_ID_STATS_FETCH, USERSTATS_FETCH, USER_ID_STATS_FETCH }
