import msToTime from './msToTime'

const completionStatus = ['liveFlows', 'scheduled', 'archive', 'hold']

const groups = {
  copyTeam: ['copy', 'copyRev', 'copyApp'],
  designTeam: ['design', 'designRev', 'designApp'],
  implQlty: ['impl', 'qc', 'fqc'],
}

const countOnlyKeyGroups = {
  copyTeam: ['CopyATR'],
  designTeam: ['DesignATR'],
  implQlty: ['bicoe'],
}

const getAverageStats = (campaignStats) => {
  const defaultCountProps = {
    count: 0,
    total: 0,
    average: null,
  }

  const defaultTimeProps = {
    totalMsToTime: 0,
    averageMsToTime: null,
  }

  // General initialization
  const averageStats = { ...defaultCountProps, ...defaultTimeProps }

  // Groups initialization
  for (const groupKey in groups) {
    averageStats[groupKey] = { ...defaultCountProps, ...defaultTimeProps }

    for (const statKey of groups[groupKey]) {
      averageStats[groupKey][statKey] = {
        ...defaultCountProps,
        ...defaultTimeProps,
      }
    }

    for (const statKey of countOnlyKeyGroups[groupKey]) {
      averageStats[groupKey][statKey] = { ...defaultCountProps }
    }
  }

  // Values count & sum
  for (const { stats } of campaignStats) {
    const hasFinalStatus = completionStatus.some((key) => stats[key])

    averageStats.count++

    Object.keys(groups).forEach((groupKey, index) => {
      let isGroupComplete = false

      const hasImplQltyStatus = groups.implQlty.some((key) => stats[key])

      if (index === 0 && !isGroupComplete) {
        const hasDesignStatus = groups.designTeam.some((key) => stats[key])
        isGroupComplete = hasDesignStatus || hasImplQltyStatus || hasFinalStatus
      }

      if (index === 1 && !isGroupComplete) {
        isGroupComplete = hasImplQltyStatus || hasFinalStatus
      }

      if (index === 2 && !isGroupComplete) {
        isGroupComplete = hasFinalStatus
      }

      const groupKeysList = groups[groupKey]
      let hasCountedGroup = false

      groupKeysList.forEach((statKey) => {
        averageStats[groupKey][statKey].count++
        averageStats[groupKey][statKey].total += stats[statKey] || 0

        !hasCountedGroup && averageStats[groupKey].count++
        hasCountedGroup = true

        averageStats[groupKey].total += stats[statKey] || 0

        averageStats.total += stats[statKey] || 0
      })

      for (const statKey of countOnlyKeyGroups[groupKey]) {
        if (stats[statKey]) {
          averageStats[groupKey][statKey].count++
          averageStats[groupKey][statKey].total += stats[statKey]
        }
      }
    })
  }

  // Groups avg calculation
  for (const groupKey in groups) {
    if (averageStats[groupKey].count > 0) {
      averageStats[groupKey].average =
        averageStats[groupKey].total / averageStats[groupKey].count || 0
      averageStats[groupKey].averageMsToTime = msToTime(
        averageStats[groupKey].average
      )
      averageStats[groupKey].totalMsToTime = msToTime(
        averageStats[groupKey].total
      )

      for (const statKey of groups[groupKey]) {
        averageStats[groupKey][statKey].average =
          averageStats[groupKey][statKey].total /
            averageStats[groupKey][statKey].count || 0
        averageStats[groupKey][statKey].averageMsToTime = msToTime(
          averageStats[groupKey][statKey].average
        )
        averageStats[groupKey][statKey].totalMsToTime = msToTime(
          averageStats[groupKey][statKey].total
        )
      }

      for (const statKey of countOnlyKeyGroups[groupKey]) {
        averageStats[groupKey][statKey].average = +(
          averageStats[groupKey][statKey].total /
            averageStats[groupKey][statKey].count || 0
        ).toFixed(2)
      }
    }
  }

  // General avg calculation
  if (averageStats.count > 0) {
    averageStats.average = averageStats.total / averageStats.count
    averageStats.averageMsToTime = msToTime(averageStats.average)
    averageStats.totalMsToTime = msToTime(averageStats.total)
  }

  return averageStats
}

export default getAverageStats
