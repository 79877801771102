export const createLoadingSelector = (actions) => (state) => {
  return actions.some((action) => state.display.loading[action])
}

export const getStartDate = ({ display }) => display.startDate

export const getEndDate = ({ display }) => display.endDate

export const getAllowedDeviation = ({ display }) => display.allowedDeviation

export const getTableColsDisplay = ({ display }) => display.tableColumnsDisplay

export const getColsToShow = ({ display }) => {
  const colsToShow = display.tableColumnsDisplay

  return {
    showCopy: colsToShow.includes('copy'),
    showDesign: colsToShow.includes('design'),
    showFinal: colsToShow.includes('final'),
  }
}

export const getEmailTypeFilter = ({ display }) => display.emailTypeFilter