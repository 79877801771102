import { format } from 'date-fns'
import getQuarterStartDate from './getQuarterStartDate'

const formatStr = 'yyyy-MM-dd'

const getQuarterFiltersHandler =
  ({ setQuarter, setStartAndEndDate }) =>
  (e) => {
    const value = e.target?.value
    setQuarter(value)

    const startDate = getQuarterStartDate(value)
    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 3,
      0
    )

    setStartAndEndDate(format(startDate, formatStr), format(endDate, formatStr))
  }

export default getQuarterFiltersHandler
