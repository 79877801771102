import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { darkTheme } from 'styles/themeClasses'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 64,
    minWidth: 192,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 1,
  },
  cardTitle: {
    fontSize: 13,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  valueRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
  },
  pointer: {
    cursor: 'pointer',
  },
  value: {
    fontSize: 16,
    fontWeight: 500,
  },
  showCol: {
    fontSize: 12,
    fontStyle: 'italic',
  }
}))

const StatsCard = ({ name, value, icon, iconColor, selected, onShowHideClick }) => {
  const classes = useStyles(darkTheme)

  return (
    <Card className={classes.root}>
      <span className={classes.cardTitle}>{name}</span>
      <div
        className={clsx(classes.valueRow, !name.includes('Success') && classes.pointer)}
        onClick={onShowHideClick}
      >
        {selected && (
          <>
            <div className={classes.iconWrapper}>
              <Icon fontSize="medium" style={{ color: iconColor }}>
                {icon}
              </Icon>
            </div>
            <div className={classes.value}>{value || '-'}</div>
          </>
        )}
        {!selected && (
          <div className={classes.showCol}>
            Click to show
          </div>
        )}
      </div>
    </Card>
  )
}

StatsCard.defaultProps = {
  onShowHideClick: () => {},
}

StatsCard.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  selected: PropTypes.bool,
  onShowHideClick: PropTypes.func,
}

export default StatsCard
