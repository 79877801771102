import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'

import StatsTableCell from './StatsTableCell'
import DeviationFlag from './DeviationFlag'

import { getAllowedDeviation } from 'store/display/selectors'

const designClass = 'designStatusColumns'
const totalDesignClass = 'totalDesignColumn'

const DesignSection = ({ stats, averageStats, show }) => {
  const allowedDeviation = useSelector(getAllowedDeviation)

  if (!show) return null

  const {
    design,
    designRev,
    designApp,
    DesignATR: designATR,
    designTotal,
    impl,
    qc,
    fqc,
  } = stats

  const hasFinalValue = impl || qc || fqc

  return (
    <>
      <StatsTableCell value={design} className={designClass} />
      <StatsTableCell value={designRev} className={designClass} />
      <StatsTableCell value={designApp} className={designClass} />
      <TableCell className={designClass}>
        {designATR || ''}
        {!!designATR && designTotal && (
          <DeviationFlag
            value={designATR}
            average={averageStats?.designTeam?.DesignATR?.average}
            tolerance={allowedDeviation.atr}
          />
        )}
      </TableCell>
      <StatsTableCell
        value={designTotal}
        className={totalDesignClass}
        average={averageStats?.designTeam?.average}
        tolerance={allowedDeviation.designTeam}
        isComplete={hasFinalValue}
      />
    </>
  );
}

DesignSection.propTypes = {
  show: PropTypes.bool,
  stats: PropTypes.object,
  averageStats: PropTypes.object,
};

export default DesignSection;
