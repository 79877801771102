import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { toggleLoggedStatus } from 'store/users/actions'

import ByCampaign from './by-campaign'
import ByClient from './by-client'
import ByClientId from './by-client/ByClientId';
import ByUser from './by-user'
import ByUserId from './by-user/ByUserId';

import Layout from './layout'
import SignIn from './SignIn'
import SignUp from './SignUp'

const AppRouter = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)

  useEffect(() => {
    dispatch(toggleLoggedStatus())
  }, [])

  if (isLoggedIn === 'checking') {
    return <div>Loading...</div>
  }

  return (
    <Switch>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/sign-in">
        <SignIn />
      </Route>
      {!isLoggedIn && <Redirect to="/sign-in" />}
      <Layout>
        <Route path="/" exact>
          <Redirect to="/by-client" />
        </Route>
        <Route path="/by-client" exact>
          <ByClient />
        </Route>
        <Route path="/by-client/:id">
          <ByClientId />
        </Route>
        <Route path="/campaigns">
          <ByCampaign />
        </Route>
        <Route path="/by-user" exact>
          <ByUser />
        </Route>
        <Route path="/by-user/:id">
          <ByUserId />
        </Route>
      </Layout>
    </Switch>
  )
}

export default AppRouter
