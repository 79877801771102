const SET_DATE_FILTER = 'SET_DATE_FILTER'
const SET_ALLOWED_DEVIATION = 'SET_ALLOWED_DEVIATION'
const SET_TABLE_COLS_DISPLAY = 'SET_TABLE_COLS_DISPLAY'
const TOGGLE_FLOW_CAMPAIGN_FILTER = 'TOGGLE_FLOW_CAMPAIGN_FILTER'
const SET_EMAIL_TYPE_FILTER = 'SET_EMAIL_TYPE_FILTER'

export default {
  SET_DATE_FILTER,
  SET_ALLOWED_DEVIATION,
  SET_TABLE_COLS_DISPLAY,
  TOGGLE_FLOW_CAMPAIGN_FILTER,
  SET_EMAIL_TYPE_FILTER,
}
