import React, { useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import StatsTable from '../stats-table'

import {
  replicateDbEmailCards,
  fetchEmailCards,
  timeoutStart,
  timeoutComplete
} from 'store/pages/actions'
import { getEndDate, getStartDate } from 'store/display/selectors'
import { getDbEmailCards } from 'store/pages/selectors'
import { EMAILCARDS_FETCH, REPLICATE_EMAILCARDS, TIMEOUT } from 'store/pages/types'
import getAverageStats from 'lib/getAverageStats'

const FlowTable = ({ dbID, expanded }) => {
  const timeoutRef = useRef()
  const dispatch = useDispatch()

  const fetchData = () => {
    dispatch(replicateDbEmailCards(dbID))
    dispatch(timeoutStart())

    timeoutRef.current = setTimeout(() => {
      dispatch(fetchEmailCards(dbID))
      dispatch(timeoutComplete())
    }, 6000)
  }

  useEffect(() => () => {
    clearTimeout(timeoutRef?.current)
  }, [])

  const dbEmailCards = useSelector(getDbEmailCards(dbID))
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)

  useEffect(() => {
    expanded && dispatch(fetchEmailCards(dbID))
  }, [expanded, startDate, endDate])

  const averageStats = useMemo(
    () => expanded && getAverageStats(dbEmailCards),
    [dbEmailCards]
  )

  if (!expanded) return null

  return (
    <StatsTable
      id={dbID}
      rows={dbEmailCards}
      type="byCampaign"
      reloadData={fetchData}
      averageStats={averageStats}
      actionTypes={[EMAILCARDS_FETCH, REPLICATE_EMAILCARDS, TIMEOUT]}
    />
  )
}

FlowTable.propTypes = {
  dbID: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
}

export default FlowTable
