import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import BusinessIcon from '@material-ui/icons/Business'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'

import './styles.css'

export const MainListItems = () => {
  const { pathname } = useLocation()

  function getIconColor(iconPath) {
    return pathname.startsWith(iconPath) ? 'primary' : 'action'
  }

  return (
    <div>
      <NavLink to="/by-client" className="link" activeClassName="activeLink">
        <ListItem>
          <ListItemIcon>
            <BusinessIcon color={getIconColor('/by-client')} />
          </ListItemIcon>
          <ListItemText primary="By Client" />
        </ListItem>
      </NavLink>

      <NavLink to="/campaigns" className="link" activeClassName="activeLink">
        <ListItem>
          <ListItemIcon>
            <AssignmentIcon color={getIconColor('/campaigns')} />
          </ListItemIcon>
          <ListItemText primary="By Campaign" />
        </ListItem>
      </NavLink>

      <NavLink to="/by-user" className="link" activeClassName="activeLink">
        <ListItem>
          <ListItemIcon>
            <PeopleIcon color={getIconColor('/by-user')} />
          </ListItemIcon>
          <ListItemText primary="By User" />
        </ListItem>
      </NavLink>
    </div>
  )
}

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
)
