import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'

import getAverageStats from 'lib/getAverageStats'
import { getUserStats } from 'store/stats/selectors'
import { setTableColumnsDisplay } from 'store/display/actions'
import { getEndDate, getStartDate } from 'store/display/selectors'

const filterProperty = 'status'

const useByUserEffects = ({ fetchData }) => {
  const dispatch = useDispatch()

  const [roleFilter, setRoleFilter] = useState('all')
  const [roleFilterOptions, setRoleFilterOptions] = useState([])

  const userStats = useSelector(getUserStats)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)

  useEffect(fetchData, [startDate, endDate])

  useEffect(() => {
    const options = userStats
      .map((v) => v.status)
      .filter((v, i, arr) => arr.indexOf(v) === i)
      .sort()

    setRoleFilterOptions(options)
  }, [userStats])

  const tableRows = userStats.filter((row) => {
    const hasTotal = !!row?.stats?.overallTotal
    const allRoles = roleFilter === 'all'
    const includesRole = !allRoles && row[filterProperty] === roleFilter

    return hasTotal && (allRoles || includesRole)
  })

  const averageStats = useMemo(
    () => getAverageStats(tableRows),
    [tableRows]
  )

  const onRoleFilterChange = (e) => {
    setRoleFilter(e.target.value)

    switch (e.target.value) {
      case 'Copywriter':
        return dispatch(setTableColumnsDisplay(['copy']))
      case 'Designer':
        return dispatch(setTableColumnsDisplay(['design']))
      default:
        return dispatch(setTableColumnsDisplay())
    }
  }

  return { tableRows, averageStats, roleFilter, roleFilterOptions, onRoleFilterChange }
}

useByUserEffects.propTypes = {
  fetchData: PropTypes.func.isRequired,
  roleFilter: PropTypes.string,
};

export default useByUserEffects;
