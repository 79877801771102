import { Search } from '@material-ui/icons';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ShuffleIcon from '@material-ui/icons/Shuffle'

import CardsRow from 'components/CardsRow'
import DeviationDialog from 'components/DeviationDialog'
import StatsTable from 'components/stats-table'
import UsersTable from 'components/UsersTable'
import TypeToggle from 'components/TypeToggle'

import getAverageStats from 'lib/getAverageStats'

import { clearClientIdStats, fetchClientIdStats } from 'store/stats/actions'
import { getEmailTypeFilter, getEndDate, getStartDate } from 'store/display/selectors'
import { CLIENT_ID_STATS_FETCH } from 'store/stats/types'

import { darkTheme } from 'styles/themeClasses'
import store from '../../store';
import { fetchEmailTypes } from '../../store/pages/actions';
import EmailTypeFilter from '../EmailTypeFilter';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  cardsFiltersWrapper: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  filtersWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  nameFilterWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    marginRight: theme.spacing(1),
    '& :first-child': {
      marginRight: theme.spacing(1),
    },
    '& :last-child': {
      flex: 1,
    }
  },
  nameFilter: {
    width: '100%'
  },
  emailTypeFilterWrapper: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  filtersActionsCardsWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  filtersActionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  usersTableWrapper: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  }
}))

const ByClientId = () => {
  const classes = useStyles(darkTheme)
  const { id } = useParams();
  const history = useHistory()
  const dispatch = useDispatch()

  const clientIdStats = useSelector((state) => state.stats.clientIdStats)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const emailType = useSelector(getEmailTypeFilter)

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false)
  const [nameFilter, setNameFilter] = useState('')

  const debouncedNameFilter = useCallback(debounce((name) => {
    setNameFilter(name)
  }, 200), [])
  const onNameFilterChange = (e) => debouncedNameFilter(e.target.value)

  store.dispatch(fetchEmailTypes())
  const fetchData = () => dispatch(fetchClientIdStats(id))

  useEffect(fetchData, [startDate, endDate, emailType])

  useEffect(() => () => {
    dispatch(clearClientIdStats())
  }, [])

  const typeTooltip = useSelector(state => state.display.typeTooltip)

  const tableRows = useMemo(() => {
    const rows = clientIdStats?.campaigns?.filter(({ type }) => type.includes(typeTooltip))

    if (nameFilter === '') {
      return rows
    }
    return clientIdStats?.campaigns?.filter((row) => row.name.toLowerCase().includes(nameFilter.toLowerCase()))
  }, [clientIdStats, typeTooltip, nameFilter])

  const averageStats = useMemo(
    () => getAverageStats(tableRows),
    [tableRows]
  )

  const onClientWorkingUserEntryDetails = (entry) => () => {
    history.push(`/by-user/${entry.id}`)
  }

  return (
    <>
      <h1 className={classes.title}>{clientIdStats?.name || '-'}</h1>
      <div className={classes.cardsFiltersWrapper}>
        <CardsRow averageStats={averageStats} />
        <div className={classes.filtersWrapper}>
          <div className={classes.nameFilterWrapper}>
            <Search />
            <TextField
                className={classes.nameFilter}
                label="Search"
                onChange={onNameFilterChange}
            />
          </div>
          <div className={classes.emailTypeFilterWrapper}>
            <EmailTypeFilter />
          </div>
          <div className={classes.actions}>
            <Tooltip
                title="Set Allowed Deviation"
                aria-label="Set Allowed Deviation"
            >
              <IconButton onClick={() => setDeviationDialogOpen(true)}>
                <ShuffleIcon />
              </IconButton>
            </Tooltip>
            <TypeToggle />
          </div>
        </div>
      </div>
      <StatsTable
        id="clientIdStats"
        rows={tableRows}
        averageStats={averageStats}
        type="byClientId"
        reloadData={fetchData}
        actionTypes={[CLIENT_ID_STATS_FETCH]}
      />
      <Grid classes={{root: classes.usersTableWrapper}} container>
        <Grid xs={4} item>
          <h2>Client&apos;s users</h2>
          <UsersTable
            users={clientIdStats?.users}
            onEntryDetails={onClientWorkingUserEntryDetails}
          />
        </Grid>
      </Grid>
      <DeviationDialog
        open={deviationDialogOpen}
        onClose={() => setDeviationDialogOpen(false)}
      />
    </>
  )
}

export default ByClientId
