import types from './types'

const initialState = {
  isLoggedIn: 'checking',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGNUP_SUCCESS:
    case types.SIGNIN_SUCCESS: {
      return { ...state, isLoggedIn: true }
    }
    case types.IS_LOGGED_IN: {
      const isLoggedIn = !!localStorage.getItem('jwt')
      return { ...state, isLoggedIn }
    }
    default:
      return state
  }
}
