import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { MenuItem, TextField, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ShuffleIcon from '@material-ui/icons/Shuffle'

import CardsRow from 'components/CardsRow'
import DeviationDialog from 'components/DeviationDialog'
import StatsTable from 'components/stats-table'

import { fetchUserStats } from 'store/stats/actions'
import { darkTheme } from 'styles/themeClasses'
import { USERSTATS_FETCH } from 'store/stats/types'
import { useStyles, useByUserEffects, UserSelectionDialog } from './'

const ByUser = () => {
  const classes = useStyles(darkTheme)
  const history = useHistory()
  const dispatch = useDispatch()

  const [deviationDialogOpen, setDeviationDialogOpen] = useState(false)
  const [userSelectOpen, setUserSelectOpen] = useState(false)

  const fetchData = () => dispatch(fetchUserStats())

  const {
    tableRows,
    averageStats,
    roleFilter,
    roleFilterOptions,
    onRoleFilterChange,
  } = useByUserEffects({ fetchData })

  const onEntryDetails = (entry) => () => {
    history.push(`/by-user/${entry.id}`)
  }

  return (
    <>
      <Grid
        className={classes.filtersActionsCardsWrapper}
        container
        spacing={1}
      >
        <Grid className={classes.filtersActionsWrapper} item>
          <TextField
            select
            className={classes.roleFilter}
            label="Role"
            value={roleFilter}
            onChange={onRoleFilterChange}
          >
            <MenuItem key={'all'} value={'all'}>
              All
            </MenuItem>
            {roleFilterOptions.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
          <Box className={classes.actions}>
            <Tooltip
              title="Set Allowed Deviation"
              aria-label="Set Allowed Deviation"
            >
              <IconButton onClick={() => setDeviationDialogOpen(true)}>
                <ShuffleIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item className={classes.cardsRowContainer}>
          <CardsRow averageStats={averageStats} />
        </Grid>
      </Grid>
      <StatsTable
        id="userStats"
        rows={tableRows}
        averageStats={averageStats}
        type="byUser"
        reloadData={fetchData}
        actionTypes={[USERSTATS_FETCH]}
        onEntryDetails={onEntryDetails}
        onHeaderFilterClick={() => setUserSelectOpen(true)}
      />
      <DeviationDialog
        open={deviationDialogOpen}
        onClose={() => setDeviationDialogOpen(false)}
      />
      <UserSelectionDialog
        open={userSelectOpen}
        onClose={() => setUserSelectOpen(false)}
      />
    </>
  )
}

export default ByUser
